import throttle from 'lodash/throttle';
import { ApplicationStore } from '../state';
import { LogoutActionType, MINUTE } from './constants';
import globalWindow from 'global';
import history from '../_start/state/history';

export const actionsToResetIdle = ['mousedown', 'keyup', 'scroll', 'touchstart', 'touchmove'];

interface TimeoutOption {
  inactiveTimeout: number;
  modalTimeout: number;
}

let _window: Window = globalWindow; // reference to window
let _store: ApplicationStore = null; // reference to the configured store
let _timeout: number = null; // ms before triggering timer reference
let _modalTimeout: number = null; // ms before triggering timer reference
let _expires: Date = null; // when the timer will elapse/expire
let _ref: number = null; // reference for clearTimeout

export const _reset = () => {
  _window = null;
  _store = null;
  _timeout = null;
  _expires = null;
  _ref = null;
};

export const _getInternalState = () => ({
  window: _window,
  store: _store,
  timeout: _timeout,
  modalTimeout: _modalTimeout,
  timerRef: _ref,
  expires: _expires,
});

export const _isFresh = (store, timeout, window) => !(_store === store && timeout === _timeout && window === _window);

export const _configureTimeout = timeout => {
  let ret = ~~timeout;
  if (ret < 1) ret = 15;
  return ret * MINUTE;
};

export const onTimeout = () => {
  const { login, auth } = _store.getState();

  if (auth?.claims?.sub || login.drawerState) {
    _store.dispatch({
      type: LogoutActionType.OpenLogoutTimeoutModal,
    });
  }
};

export const resetTimerRaw = () => {
  _window.clearTimeout(_ref);
  _expires = new Date(Date.now() + _timeout + _modalTimeout);
  _window.localStorage.setItem('inactivityTimeout', _expires.toISOString());
  _ref = _window.setTimeout(onTimeout, _timeout);
};

// registered for focus event to check expiration - resume from suspend
export const checkExpires = () => {
  if (new Date() > _expires) {
    history.push('/logout');
  }
};

// only fire the reset once every 10 seconds - prevent event flooding
export const resetTimer = throttle(resetTimerRaw, 10000, { trailing: false });

export const unregisterTimer = () => {
  _window.clearTimeout(_ref);
  _window.removeEventListener('focus', checkExpires);
  actionsToResetIdle.forEach(action => _window.removeEventListener(action, resetTimer));
};

export const registerTimer = () => {
  actionsToResetIdle.forEach(action => _window.addEventListener(action, resetTimer));
  _window.addEventListener('focus', checkExpires);
  resetTimerRaw();
};

const defaultOptions: TimeoutOption = {
  inactiveTimeout: 15,
  modalTimeout: 1,
};

// Initialize globals / side effects to help with testing
export const init = (store: ApplicationStore, options = defaultOptions, window: Window = globalWindow) => {
  const timeout = _configureTimeout(options.inactiveTimeout);
  const modalTimeout = _configureTimeout(options.modalTimeout);
  if (!_isFresh(store, timeout, window)) return; // nothing to do
  _window = window;
  _store = store;
  _timeout = timeout;
  _modalTimeout = modalTimeout;

  resetTimerRaw();
};
