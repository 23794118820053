import fclone from 'fclone';
import unwrapError from './unwrap-error';
import base from '../../base';

const getLoginUrl = () => {
  const returnUrl = encodeURIComponent(location.href);
  if (base.login.indexOf('${returnUrl}') >= 0) {
    return base.login.replace('${returnUrl}', returnUrl);
  }

  const sep = base.login.indexOf('?') < 0 ? '?' : '&';
  return `${base.login}${sep}returnUrl=${returnUrl}`;
};

export default function showError(error) {
  console.error(error);
  console.log(fclone(unwrapError(error)));
  let title = 'Unexpected Error';
  let message = error.message || '';

  if (error.statusCode === 403) {
    switch (error.code) {
      case 'AUTH_REQUIRED':
        title = 'Authorization Required';
        message = 'Redirecting to login...';
        window.location.replace(getLoginUrl());
        break;
      case 'AUTH_EXPIRED':
        title = 'Expired Authorization';
        message = `<a href="${getLoginUrl()}">Login Again</a>`;
        break;
    }
  }
  const root = document.getElementById('root');
  const body = document.body;
  body.className = body.className.replace(/\s*(loading)\b/g, '') + ' error';
  root.innerHTML = `
    <div>
      <div id="error">
        <h1>${title}</h1>
        <p>${message}</p>
      </div>
    </div>
  `;
}
