export enum LogoutActionType {
  OpenLogoutTimeoutModal = 'LOGOUT:OPEN_LOGOUT_TIMEOUT_MODAL',
  CloseLogoutTimeoutModal = 'LOGOUT:CLOSE_LOGOUT_TIMEOUT_MODAL',
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;

export interface LogoutState {
  isLogoutTimeoutModalOpen: boolean;
  logoutModalTimeout: string;
}

export const defaultState: LogoutState = {
  isLogoutTimeoutModalOpen: false,
  logoutModalTimeout: null,
};
