import window from 'global';
import React from 'react';
import ReactDOM from 'react-dom';
import { showError, addErrorHandling } from './_start/errors';
import history from './_start/state/history';
import { init as initializeInactivityTimer } from './logout/inactivity';
import initializeWindowTracker from './logout/window-tracker';

async function main() {
  // adds global error handling - prefer error trap in React
  addErrorHandling();
  initializeWindowTracker();

  const [{ checkToken }, { default: state }, { default: api }, { default: base }] = await Promise.all([
    import('./security'),
    import('./_start/state'),
    import('./api/client'),
    import('./base'),
  ]);

  // pre-load petitionTypes
  base.data = base.data || {};
  base.data.petitionTypes = await api.petition.getTypes();

  const auth = await checkToken(history);
  const lastState = state.restore(auth);
  const store = (window.__STORE__ = state.createStore(history, lastState));

  initializeInactivityTimer(
    store,
    {
      inactiveTimeout: base?.client?.ui?.logout?.inactivityTimeout || 15,
      modalTimeout: base?.client?.ui?.logout?.inactivityModalTimeout || 1,
    },
    window
  );

  const { default: Root } = await import('./app/Root');
  const root = document.getElementById('root');
  const body = document.body;
  body.className = body.className.replace(/\s*loading\b/, '');
  ReactDOM.render(React.createElement(Root, { history, store }, null), root);
}

document.addEventListener('DOMContentLoaded', () => main().catch(showError));
