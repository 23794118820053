import addErrorHandlingFn from './add-error-handling';
import showErrorFb from './show-error';
import unwrapErrorFb from './unwrap-error';

export const addErrorHandling = addErrorHandlingFn;
export const showError = showErrorFb;
export const unwrapError = unwrapErrorFb;

export default {
  addErrorHandling,
  showError,
  unwrapError,
};
