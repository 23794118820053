import global from 'global';

export const KEY = 'wt';
export const KEYT = 'wtt';
export const TIMER = 5000;

let window: Window = global;
let windowId: number = Date.now();
let windowList: number[] = [];

export const __getState = () => ({ window, windowId, windowList });

export function __setState(w, wid, wl) {
  window = w;
  windowId = wid;
  windowList = wl;
}

export function _clearSession() {
  // If there's a token, stash in sessionStorage, in case last tab is being refreshed
  const token = window.localStorage.getItem('token');
  if (token) {
    // only use if within 10 seconds
    window.sessionStorage.setItem('tokenUntil', (Date.now() + 10000).toString());
    window.sessionStorage.setItem('token', token);
  }

  window.localStorage.removeItem('token');
  window.localStorage.removeItem('inactivityTimeout');
  window.sessionStorage.removeItem('__STATE__');
}

export const _handleClose = (handleClear: () => void) => () => {
  const before = windowList.length;
  windowList = windowList.filter(e => e !== windowId);

  // last window, clear details
  if (!windowList.length) {
    handleClear();
    window.localStorage.removeItem(KEY);
    window.localStorage.removeItem(KEYT);
    return;
  }

  if (windowList.length !== before) {
    // list changed, update key
    window.localStorage.setItem(KEY, JSON.stringify(windowList));
  }
};

export function _handleStorageEvent(e) {
  if (e?.key === KEY) {
    windowList = JSON.parse(e.newValue || window.localStorage.getItem(KEY) || '[]');
  }
}

export function _setTimer() {
  window.localStorage.setItem(KEYT, JSON.stringify(Date.now() + TIMER));
}

export function _getValues(): [number[], number] {
  return [JSON.parse(window.localStorage.getItem(KEY) || '[]'), JSON.parse(window.localStorage.getItem(KEYT) || '0')];
}

export function _checkValues(wl: number[], t: number, handleClear: () => void) {
  if (wl?.length && t > Date.now()) return;
  // first window or past timer, start fresh
  handleClear();
}

export function _ensureCurrentWindow(wl: number[]) {
  windowList = wl;
  if (windowList.indexOf(windowId) < 0) {
    windowList.push(windowId);
    window.localStorage.setItem(KEY, JSON.stringify(windowList));
  }
}

export function _registerEvents(handleStorage, handleClose, handleClear, setTimer) {
  // setup events and timer
  const onClose = handleClose(handleClear);
  window.addEventListener('storage', handleStorage, false);
  window.addEventListener('beforeunload', onClose);
  window.addEventListener('unload', onClose);
  window.setInterval(setTimer, TIMER);
  setTimer();
}

export const _defaultHandlers = {
  getValues: _getValues,
  checkValues: _checkValues,
  ensureWindow: _ensureCurrentWindow,
  handleClear: _clearSession,
  handleStorage: _handleStorageEvent,
  handleClose: _handleClose,
  setTimer: _setTimer,
  register: _registerEvents,
};

export const _init =
  (h = _defaultHandlers) =>
  () => {
    const [wl, t] = h.getValues();
    h.checkValues(wl, t, h.handleClear);
    h.ensureWindow(wl);
    h.register(h.handleStorage, h.handleClose, h.handleClear, h.setTimer);
  };

export default _init();
