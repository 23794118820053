import showError from './show-error';

export default function addErrorHandling() {
  window.addEventListener('error', event =>
    showError({
      message: 'See console log for details.',
      event: {
        ...event,
        error: undefined,
      },
      error: {
        ...event.error,
        message: event.error.message,
        stack: event.error.stack,
      },
    })
  );
  window.addEventListener('unhandledrejection', function (event: PromiseRejectionEvent) {
    // A mozilla built in is causing this rejection
    // only log the info, don't show the error if there's no reason or promise.
    if (!event.reason || !event.promise) {
      event?.preventDefault?.();
      console.error('Unhandled Rejection (no promise or reason)', event);
      return;
    }

    // Captcha times out after the mounted element is unmounted from the page.
    // With the error not being handled, it cause the whole application to
    // crash. This prevents that crash, but there might be other errors with a
    // reason of "Timeout". Need to have a more specific comparision to catch
    // only this error.
    if (event.reason === 'Timeout') {
      return;
    }

    const promise = event.promise;
    let reason = event.reason;
    if (Array.isArray(reason) || !(reason instanceof Object)) {
      reason = { message: 'Unstructured Error', detail: reason };
    }
    reason = { ...reason, message: reason.message, stack: reason.stack };

    showError({
      message: 'Coding error, unhandled promise, see log for details.',
      innerError: reason,
      promise,
    });
  });
}
